<template>
  <div>
    <div class="header-top">
      <div class="left-wrap">
        <div class="header-title">线索学员</div>
        <div class="student-info">
          <img src="https://cdnwukong.com/public/im/user.png" class="student-avtor" />
          <span style="margin-left: 10px">{{ detailData.name }}</span>
          <span style="color: rgb(102, 102, 102)">（{{ detailData.stuNumber }}）</span>
          <span class="promotionAmbassador" v-if="detailData.resellerId"
            >
            <img src="@/assets/icon-ambassador.png" class="ambassador" />
            推广大使
          </span>
        </div>
      </div>
      <div class="right-wrap">
        <!-- <span class="operator-btn"> 学员拓科 </span>
         <span class="operator-btn"> 一家多娃 </span> -->
      </div>
    </div>
    <div class="clue-wrap">
      <a-descriptions class="descriptions-wrap" title="线索详情">
        <a-descriptions-item label="线索名称">
          {{ detailData.name || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="进线类型">
          {{ detailData.createType || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="线索类型">
          {{ detailData.type || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="线索阶段">
          {{ detailData.stage || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="线索来源">
          {{ detailData.mainSource || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="所属科目">
          {{ detailData.subject || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="推荐人">
          <span v-if="detailData.recommendStuName">
            {{ detailData.recommendStuName }} ({{ detailData.recommendStuId }})
          </span>
          <span v-else>-</span>
        </a-descriptions-item>
        <a-descriptions-item label="分销大使">
          <span v-if="detailData.resellerName"> {{ detailData.resellerName }} ({{ detailData.resellerCode }}) </span>
          <span v-else>-</span>
        </a-descriptions-item>
        <a-descriptions-item label="创建人">
          {{ detailData.whoCreatedName || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="创建时间">
          {{ detailData.whenCreated || '-' }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions class="descriptions-wrap" title="线索关联学员">
        <a-descriptions-item label="手机号">
          {{ detailData.mobile || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="邮箱">
          {{ detailData.email || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="学号">
          {{ detailData.stuNumber || '-' }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions class="descriptions-wrap" title="线索约课">
       <a-descriptions-item label="所有人">
          {{ detailData.ownerName || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="学生课节状态">
          {{ detailData.courseResult || '-' }}
        </a-descriptions-item>
          <a-descriptions-item label="试听时间（北京）">
          {{ detailData.courseStartTime || '-' }}
        </a-descriptions-item>
         <a-descriptions-item label="课节取消类别">
          {{ detailData.category || '-' }}
        </a-descriptions-item>
        <!-- <a-descriptions-item label="销售负责人">
          {{ detailData.ccDutyOwnerName || '-' }}
        </a-descriptions-item>
        <a-descriptions-item label="约课负责人">
          {{ detailData.icDutyOwnerName || '-' }}
        </a-descriptions-item> -->
        <a-descriptions-item label="未试听原因">
          {{ detailData.notAttendClassReason || '-' }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';

const session = storage.createStore(sessionStorage);

export default {
  name: 'clueDetail1',
  data() {
    return {
      detailData:session.get('clueDetail'),
      studentProfile: session.get('studentProfile'),
    };
  },
  mounted() {
  
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-descriptions-item-label {
  color: rgba(31, 45, 61, 0.4);
}
/deep/ .ant-descriptions-item-content {
  color: #1f2d3d;
}
/deep/ .ant-descriptions-title {
  &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 6px 0 0;
    width: 3px;
    height: 16px;
    background-color: #41cc83;
    border-radius: 0px 2px 2px 0px;
  }
}
.header-top {
  padding: 18px 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.header-title {
  font-size: 16px;
  font-weight: 600;
}
.student-avtor {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.student-info {
  font-size: 16px;
  color: #333;
  margin-top: 5px;
}
.operator-btn {
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  margin-left: 10px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  cursor: pointer;
}
.promotionAmbassador {
  background: #ffbf00;
  color: #fff;
  font-size: 13px;
  padding: 4px 6px;
  margin-left: 10px;
  border-radius: 3px;
}
.ambassador {
  width: 9px;
  height: 9px;
}
.clue-wrap {
  background-color: #fff;
  padding: 18px 20px 0;
}
</style>
